import React from 'react';
import { Link } from 'gatsby';
import AdviceView from '../views/AdviceView/AdviceView';
import { introStyle } from '../styles/global';

import vectorPreConsultation from '../images/vectorPreConsultation.svg';
import SEO from '../components/SEO';

const contactUsText = (
  <p>
    Vous avez toujours une interrogation face à votre première visite à l’une de
    nos cliniques Maxillo Québec, vous pouvez rejoindre l’un des membres de
    notre équipe pour de plus amples renseignements.
  </p>
);

const PremiereConsultationPage = () => {
  return (
    <>
      <SEO
        title='Première consultation'
        description='Vous avez un rendez-vous à l’une de nos cliniques Maxillo Québec Lebourgneuf et Lévis, voici quelques conseils en vue de votre première consultation.'
      />

      <AdviceView
        title='Première consultation'
        iconSrc={vectorPreConsultation}
        renderContactUsText={contactUsText}
      >
        <p css={introStyle}>
          Vous avez un rendez-vous à l’une de nos deux cliniques spécialisées
          Maxillo Québec? Voici quelques conseils en vue de votre première
          consultation.
        </p>

        <ul>
          <li>
            Veuillez remplir votre{' '}
            <Link to='/questionnaire-medical'>
              questionnaire médical en ligne
            </Link>{' '}
            avant le jour de votre consultation initiale.
          </li>
          <li>
            Veuillez arriver quelques minutes avant l’heure de votre
            rendez-vous.
          </li>
          <li>
            Apportez la documentation remise par votre dentiste, votre dentiste
            spécialiste ou votre médecin.
          </li>
          <li>
            Normalement, votre référent nous aura fait parvenir vos
            radiographies. Par contre, si votre dentiste vous les a remises en
            main propre, prière de les apporter à votre rendez-vous.
          </li>
          <li>
            Apportez la liste de vos médicaments, de vos allergies et de vos
            produits naturels ainsi que le nom de votre pharmacie.
          </li>
          <li>
            Si votre dentiste ou orthodontiste vous a remis les moulages de vos
            dents, veuillez les apporter à la consultation initiale.
          </li>
          <li>
            Si vous êtes âgé de moins de 18 ans, nous vous suggérons d’être
            accompagné lors de la consultation initiale.
          </li>
          <li>
            Si vous avez des questions, inscrivez-les sur un papier avant votre
            rendez-vous. Parfois, on oublie!
          </li>
        </ul>

        <h2>Paiement et assurances dentaires</h2>
        <ul>
          <li>
            Les honoraires doivent être payés le jour de votre rendez-vous en
            argent comptant, par carte de débit ou par carte de crédit Visa ou
            MasterCard, et ce, même si vous détenez une assurance dentaire.
          </li>
          <li>
            Si vous détenez une assurance dentaire, nous remplirons un
            formulaire standardisé pour le remboursement des honoraires
            professionnels. Vous pourrez l’annexer à celui de votre compagnie
            d’assurance et procéder à la réclamation.
          </li>
        </ul>
      </AdviceView>
    </>
  );
};

export default PremiereConsultationPage;
